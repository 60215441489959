import Rails from "@rails/ujs"
// import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
// Turbolinks.start()
ActiveStorage.start()

require("jquery")
require("./main.js")

import "chartkick"

// import "@fortawesome/fontawesome-free/js/all"

$(function() {
	/**********************
	 * 関数定義
	**********************/
  console.log("main");
  
	// 共通変数
	let scrollPos;

	// 背景ロック
	function backgroundLockControl() {
		$(document).on("click", "#js__mobile-menu--open, #js__search--modal-window_open, #js__m-screen--dropzone_modal_open, .js__image--metaname_change, .js__image-modal--open, .js__jobs--form_modal, #js__jobs--form_address-search, #js__jobs--csv-import-modal, .js__entries--search-modal, .js__background-lock", () => {
      if (!$("body").hasClass("fixed")) {
        scrollPos = jQuery(window).scrollTop();
        lockBackground(scrollPos);
      }
		});

		$(document).on("click", "#js__mobile-menu--close, #js__search--modal-window_close, .js__album--action_close, #js__m-screen__modal--close, .js__m-screen__modal--close, #js__m-screen__modal--execute, .js__m-screen__modal--execute, #js__album--modal-metaname_change_cancel, #js__loading-animation--result_back_non-link", (e) => {
      if ($(e.currentTarget).attr("data-bg_unlock") !== "false") {
    		unlockBackground(scrollPos);
      }
		});

    // 背景ロック
    function lockBackground(pos) {
      $("body").addClass("fixed").css("top", -pos);
    }

    // 背景アンロック
    function unlockBackground(pos) {
      const currentPos = pos === 0 || pos === null || pos === undefined ? $("body").css("top") : pos;
      $("body").removeClass("fixed").css("top", 0);
      window.scrollTo(0, Math.abs(parseInt(currentPos)));
    }
  }
  
  // 管理画面サイドバー表示切替
  function toggleSidebar() {
    // Bewin管理画面の場合は無効
    if (!$("#js__sidebar-control--toggle-btn").length) {
      return false;
    }

    // 企業管理画面の場合は処理開始
    const wrapperElm = $("#js__sidebar-control");

    $("#js__sidebar-control--toggle-btn").on("click", () => {
      const sidebarState = wrapperElm.attr("data-sidebar") === "shown" ? "hidden" : "shown";
      wrapperElm.attr("data-sidebar", sidebarState);

      $.ajax({
        type: "POST",
        url: `//${location.host}/company/sidebar_state`,
        data: {
          state: sidebarState,
        },
        dataType: "json",
      }).done(function (data, textStatus, jqXHR) {
        // console.log("success");
        // console.log(data);
        // console.log(textStatus);
      }).fail(function (jqXHR, textStatus, errorThrown) {
        console.log("error");
        console.log(textStatus);
        console.log(errorThrown);
      });
    });
  }

  // 求人詳細画面の応募ボタン（追従）
  function stickyBtn() {
    $(window).off("resize");
    $(window).off("scroll");

    if (document.getElementById("js__job-details")) {
      let btnElem;
      let btnElemSticky;
      let bottomMargin;
      let fixPos;
      let winHeight;
      let btnState;

      // 応募ボタンの固定位置取得
      setParams();

      // 画面リサイズ時にも応募ボタンの固定位置を取得
      $(window).on("resize", () => {
        setParams();
      });

      // スクロールイベント（画面下部まで移動すると応募ボタンが固定される）
      $(window).on("scroll", event => {
        let scroll = $(event.currentTarget).scrollTop();
        let btnHeight = $("#js__job-btn--sticky").height();
        let btnPos = scroll + winHeight - btnHeight - bottomMargin;

        if (btnPos >= fixPos && btnState === "sticky") {
          $(btnElem).removeClass("js__is-sticky");
          btnState = "fixed";
        } else if (btnPos < fixPos && btnState === "fixed") {
          $(btnElem).addClass("js__is-sticky");
          btnState = "sticky";
        }
      });
    
      // 応募ボタンの固定位置取得関数
      function setParams() {
        btnElem = $("#js__job-btn--sticky");
        btnElemSticky = $("#js__job-btn--sticky.js__is-sticky");
        bottomMargin = parseInt($(btnElemSticky).css("bottom")) || 0;
        fixPos = $("#js__job-btn").offset().top;
        winHeight = $(window).height();
        btnState = $(btnElem).hasClass("js__is-sticky") ? "sticky" : "fixed";
      }
    }
  }

  // 管理画面サイドバーのナビメニュー展開
  $(".js__m-screen--navi").on("click", (e) => {
    const elemId = $(e.currentTarget).attr("for");
    
    if (!$("#" + elemId).prop("checked")) {
      $(".js__m-screen--navi_checkbox").prop("checked", false);
    }
  });
  
  // DB保存・更新
  // 更新するボタン押下で処理中アニメーションを表示
  $(document).on("click", "#js__m-screen--loading-animation_start, .js__m-screen--loading-animation_start", () => {
    startProcessingAnimation();
  });

  // 処理中アニメーション
  // オブジェクト定義
  const objAnime = $("#js__loading-animation");

  // 処理中アニメーション
  // アニメーション開始
  const startProcessingAnimation = () => {
    // アニメーションを表示
    objAnime.addClass("add show");
    
    // 背景固定（bodyにfixedクラスが付与されていない場合は付与する）
    if (!$("body").hasClass("fixed")) {
      const scrollVal = jQuery(window).scrollTop();
      $("body").addClass("fixed").css("top", -scrollVal);
    }
  }

  // history back for ATS website job articles
  const jobArticleHistoryBack = () => {
    $("#js__history-back").on("click", (e) => {
      e.preventDefault();
      history.back();
    });
  }

	/**********************
	 * 関数登録
	**********************/

	// 全ページ共通
  backgroundLockControl();
  toggleSidebar();
  stickyBtn();
  jobArticleHistoryBack();
});